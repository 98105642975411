import { isBrazil } from '@utils/helper-region'

export default {
  methods: {
    getHelpText(type, score) {
      return this.$t(`component.driverBehavior.helpText.${type}.${this.getScoreLevelEnum(score)}`)
    },
    getScoreLevelEnum(score) {
      if (isBrazil()) {
        return score
      } else {
        return this.getScoreLevelEnumMX(score)
      }
    },
    getScoreLevelEnumMX(scoreValue) {
      if (scoreValue >= 1 && scoreValue <= 25) return 'improve';

      if (scoreValue <= 50 && scoreValue >= 26) return 'regular';

      if (scoreValue <= 75 && scoreValue >= 51) return 'good';


      if (scoreValue >= 76) return 'excellent';

      return 'notfound';
    },
  },
}
