<script>
import moment from 'moment-timezone';
import { formatPrice } from '@utils/numbers';

export default {
  name: 'recurrency-card-widget',
  components: {},
  props: {
    to: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    amount: {
      type: [String, Number],
      default: '',
    },
    nextDate: {
      type: String,
      default: '',
    },
    paymentMethodType: {
      type: String,
      default: '',
    },
    paymentMethodUpdatedAt: {
      type: String,
      default: ''
    },
    weeklyPayDay: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'icon-people',
    },
    id: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      progress: 0,
      variant: 'primary',
    };
  },
  computed: {
    getId() {
      if (this.id) {
        return this.id;
      } else {
        const name = this.title.toLowerCase().replace(' ', '-');
        return `card-${name}`;
      }
    },
    formatedAmount() {
      return (this.amount / 100).toFixed(2);
    },
    formatedNextDate() {
      return moment(this.nextDate).format('DD/MM/YYYY');
    },
    hasRecurrencyData() {
      return this.amount && this.weeklyPayDay && this.nextDate && this.paymentMethodType;
    },
  },
  methods: {
    goTo(link) {
      this.$router.push({ path: link });
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value * 100);
    },
  },
};
</script>

<template>
  <b-card
    @click="goTo(to)"
    class="card-pointer"
    :class="{ 'card-active': this.active }"
    :id="getId"
  >
    <div class="h3 text-muted text-right mb-0">
      <i :class="{ [this.icon]: this.icon, 'icon-active': this.active }"></i>
    </div>

    <div class="h4 mb-0 font-weight-bold card-value" :title="value">{{ value }}</div>

    <small
      class="text-muted text-uppercase font-weight-bold"
      :class="{ 'text-active': this.active }"
    >{{ title }}</small>

    <div v-if="hasRecurrencyData">
      <span class="d-block">{{ $t('recurrency.labels.amount') }}: {{ setPriceFormat($t('payments.labels.currency'), formatedAmount) }}        
        </span>

      <span class="d-block">{{ $t('recurrency.labels.next-date') }}: {{ formatedNextDate }}</span>

      <span class="d-block">
        {{ $t('recurrency.labels.weekly-pay-day') }}:
        {{  $t(`recurrency.labels.${weeklyPayDay.toLowerCase()}`) }}
        ({{ $t(`recurrency.labels.${paymentMethodType}`) }})
      </span>
    </div>
  </b-card>
</template>

<style scoped lang="scss">
span {
  color: #73818f;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 80%;
}
.card-pointer {
  cursor: pointer;
}
.card-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-active {
  background-color: #1b8eb7;
  color: #fff;
  small {
    color: #fff !important;
  }
}
.icon-active {
  color: #fff;
}
</style>
