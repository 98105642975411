export const BADGE_BR = {
  EXCELLENT: '#4FC2F7',
  GOOD: '#00C852',
  REGULAR: '#FFEB3A',
  IMPROVE: '#FF9800',
  BAD: '#C62828',
  NONE: '#EBEBEB',
}

export const BADGE_MX = {
  EXCELLENT: '#018f10',
  GOOD: '#80C687',
  REGULAR: '#FEFF03',
  IMPROVE: '#F44336',
}
