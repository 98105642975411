var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "text-center weekly-kms", staticStyle: { height: "300px" } },
    [
      _c("div", { staticClass: "weekly-card" }, [
        _c("div", { staticClass: "text-updated mt-1" }, [
          _vm._v(" " + _vm._s(_vm.$t("drivers.weekly.WeeklyKMactual")) + " "),
          _vm.WeeklyKMActual
            ? _c("span", { staticClass: "cycle-text" }, [
                _vm._v(
                  _vm._s(
                    `( ${_vm
                      .$moment(_vm.WeeklyKMActual, "YYYY-MM-DD", true)
                      .format("DD/MM")} ${_vm.$t("drivers.weekly.until")} ${_vm
                      .$moment(_vm.WeeklyKMActual, "YYYY-MM-DD", true)
                      .add(6, "days")
                      .format("DD/MM")} )`
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm.actual_km
          ? _c("div", { staticClass: "h1 mb-0" }, [
              _vm._v(" " + _vm._s(_vm.actual_km) + " "),
              _c("small", [_vm._v(" km ")]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "weekly-card" }, [
        _c("div", { staticClass: "text-updated mt-1" }, [
          _vm._v(" " + _vm._s(_vm.$t("drivers.weekly.WeeklyKMlast")) + " "),
          _vm.WeeklyKMLast
            ? _c("span", { staticClass: "cycle-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `( ${_vm
                        .$moment(_vm.WeeklyKMLast, "YYYY-MM-DD", true)
                        .format("DD/MM")} ${_vm.$t(
                        "drivers.weekly.until"
                      )} ${_vm
                        .$moment(_vm.WeeklyKMLast, "YYYY-MM-DD", true)
                        .add(6, "days")
                        .format("DD/MM")} )`
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _vm.last_km
          ? _c("div", { staticClass: "h1 mb-0" }, [
              _vm._v(" " + _vm._s(_vm.last_km) + " "),
              _c("small", [_vm._v(" km ")]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }