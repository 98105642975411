<template>
  <b-card v-if="booking.status === 'ACTIVE' && contract.id">
    <b-row style="padding: 25px 10px">
      <b-col>
        <div style="display: flex; align-items: center;">
          <img
            style="width: 30px; height: 30px; margin-right: 5px;"
            src="@assets/logos/lw-logo.webp"
          >
          <span class="driver-status-title">{{ cardObject.title }} Dia{{ Math.abs(cardObject.title) > 1 ? 's' : '' }}</span>
        </div>
        <div>
          <span class="driver-status-subtitle truncate">FECHAMENTO DO CONTRATO</span>
        </div>
      </b-col>
      <b-col v-b-popover.hover="subtitleHover" style="text-align: center; align-self: self-end; cursor: pointer;">
        <i :class="`${cardObject.icon} text-${cardObject.color} font-4xl`" />
        <b-col style="text-align: center; font-size: 12px;">
          <span :class="`font-weight-bold text-${cardObject.color}`">{{ cardObject.subtitle }}</span>
        </b-col>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DriverContractStatus',
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contract: {}
    }
  },
  computed: {
    cardObject() {
      return {
        title: this.getTitle(),
        cnhExpired: this.cnhExpired(),
        cnhContractExpired: this.cnhContractExpired(),
        subtitle: this.getTitle() < 0 ? 'Contrato Vencido' : this.cnhExpired() ? 'CNH Vencida' : this.cnhContractExpired() ? 'CNH À Vencer' : 'Em Dia',
        color: this.getTitle() < 0 || this.cnhExpired() ? 'danger' : this.cnhContractExpired() ? 'warning' : 'success',
        icon: this.getTitle() < 0 ? 'fa fa-times' :  this.cnhExpired() || this.cnhContractExpired() ? 'fa fa-warning' : 'fa fa-check',
      }
    },
    subtitleHover() {
      return {
        html: true,
        title: () => {
          return 'Como verificar a validade do contrato?';
        },
        content: () => {
          return `<span class="text-success font-weight-bold text-capitalize">Em Dia</span>:
            O Contrato e CNH estão em dia.
          </br>\
                  <span class="text-danger font-weight-bold text-capitalize">CNH Vencida</span>:
                    A CNH está vencida, momento para subir uma cnh atualizada.
                  </br>\
                  <span class="text-warning font-weight-bold text-capitalize">CNH À Vencer</span>:
                    A CNH irá vencer antes do fechamento do contrato.
                  </br>\
                  <span class="text-danger font-weight-bold text-capitalize">Contrato Vencido</span>:
                    Já passou do periodo da devolutiva do veículo, o Contrato está vencido.`;
        },
      };
    }
  },
  mounted() {
    this.$fleetProvider.get(`/fleet-tickets/tickets/provider/contract/${this.booking.id}`)
      .then((res) => {
        this.contract = res.data
      })
  },
  methods: {
    getTitle() {
      if (moment(this.contract.data_fim).isValid()) {
        return this.getDiff(this.contract.data_fim)
      }
      return this.getDiff(this.contract.data_inicio, 4);
    },
    cnhExpired() {
      const { data_expiracao_cnh } = this.contract
      if (!moment(data_expiracao_cnh).isValid()) {
        return false;
      }
      const expireDay = this.getDiff(data_expiracao_cnh);
      if (expireDay < 0) {
        return expireDay;
      }
      return false;
    },
    cnhContractExpired() {
      const { data_expiracao_cnh, data_inicio } = this.contract
      if (!moment(data_expiracao_cnh).isValid()) {
        return false;
      }
      const expireDay = this.getDiff(data_expiracao_cnh, null, moment(data_inicio).add(4, 'years'));
      if (expireDay < 0) {
        return expireDay;
      }
      return false;
    },
    getDiff(date, addYears = null, dateCompare = null) {
      const now = moment(dateCompare).isValid() ? moment(dateCompare) : moment();
      const result = moment(date);
      if (addYears) {
        result.add(addYears, 'years')
      }
      return result.diff(now, 'days')
    },
  }
};
</script>

<style lang="sass" scoped>
.driver-status-title
  font-size: 30px
  font-weight: bold

.driver-status-subtitle
  font-size: 12px
  font-weight: bold
  color: #73818f

.truncate
  display: block
  min-width: 140px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
</style>
