import { isMexico } from '@utils/helper-region';
import { BADGE_MX } from '@enums/badges';
import ColorSquareBadge from '@components/badges/color-square-badge';
import { getDbsScoreRangeInfoBR } from '@utils/dbsScoreRange';

export default {
  components: {
    ColorSquareBadge,
  },
  methods: {
    getBadgeColor(score) {
      return isMexico() ? this.badgeColorMX(score) : this.badgeColorBR(score);
    },
    badgeColorBR(score) {
      return getDbsScoreRangeInfoBR(score).bgColor;
    },
    badgeColorMX(score) {
      if (score >= 76) return BADGE_MX.EXCELLENT;
      if (score <= 75 && score >= 51) return BADGE_MX.GOOD;
      if (score <= 50 && score >= 26) return BADGE_MX.REGULAR;
      if (score <= 25) return BADGE_MX.IMPROVE;
    },
  },
};
