var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$apollo.queries.driver.loading
        ? _c(
            "div",
            { staticClass: "animated fadeIn" },
            [
              _c("driver-lock-status", { attrs: { driver: _vm.driver } }),
              _c(
                "b-card-group",
                { staticClass: "mb-4", attrs: { deck: "" } },
                [
                  _vm.driver.booking && _vm.driver.booking.recurrency_data
                    ? _c("recurrency-card-widget", {
                        attrs: {
                          to:
                            "/drivers/profile/" +
                            _vm.$route.params.id +
                            "/bookings",
                          title: _vm.$t("recurrency.labels.active_plan"),
                          "next-date": _vm.recurrency.next_date,
                          amount: _vm.recurrency.amount,
                          "weekly-pay-day": _vm.recurrency.weekly_pay_day,
                          "payment-method-type": _vm.recurrency.payment_method,
                          value: _vm.driver.booking.plan.name,
                          icon: "fa fa-dollar",
                        },
                      })
                    : _vm._e(),
                  _vm.driver_trips && _vm.driver_trips.count
                    ? _c("card-widget", {
                        attrs: {
                          title: "Total de corridas",
                          value: _vm.driver_trips.count.toString(),
                          icon: "fa fa-road",
                        },
                      })
                    : _vm._e(),
                  _c("total-fines-card", {
                    attrs: { fines: _vm.driver.total_fines },
                  }),
                  _vm.driver.booking && _vm.groups.includes("SQUAD-FLEET")
                    ? _c("driver-contract-status", {
                        attrs: { booking: _vm.driver.booking },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "3" } },
                    [
                      _vm.driver.status === "SIGNUP" &&
                      _vm.driver.mileageProfile.isLowMileage !== null
                        ? _c("b-card", [
                            _vm.driver.mileageProfile.isLowMileage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-0 font-weight-bold card-value text-muted text-uppercase font-weight-bold",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-check text-success",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "text-success" },
                                      [_vm._v("Driver Baixa KM: Aprovado")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.driver.mileageProfile.isLowMileage
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-0 font-weight-bold card-value text-muted text-uppercase font-weight-bold",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-ban text-danger",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("Driver Baixa KM: Reprovado"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("driver-behavior-card"),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "3" } },
                    [_c("weekly-traveled", { attrs: { driver: _vm.driver } })],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "6" } },
                    [
                      _c("driver-mileage-chart", {
                        attrs: { driver: _vm.driver },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-group",
                { staticClass: "driver-data mb-4" },
                [
                  _vm.driver.booking
                    ? _c("b-card", { attrs: { title: "Aluguel" } }, [
                        _vm.driver.booking.car
                          ? _c(
                              "p",
                              { staticClass: "card-text" },
                              [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      to: "/fleet/" + _vm.driver.booking.car.id,
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.driver.booking.car.brand.name
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.driver.booking.car.model.name
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("span", { staticClass: "detail" }, [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.driver.booking.car.license_plate
                                      ) +
                                      ")"
                                  ),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("Carro"),
                                ]),
                              ],
                              1
                            )
                          : _c("p", { staticClass: "card-text" }, [
                              _c("strong", [_vm._v("Aguardando...")]),
                              _c("br"),
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v("Carro"),
                              ]),
                            ]),
                        _c("p", { staticClass: "card-text" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.driver.booking.started_at,
                                  "DD/MM/YYYY"
                                )
                              ) + " "
                            ),
                            _vm.driver.booking.finished_at
                              ? _c("span", [
                                  _vm._v(
                                    "a " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          _vm.driver.booking.finished_at,
                                          "DD/MM/YYYY"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          !_vm.driver.booking.finished_at
                            ? _c("span", { staticClass: "detail" }, [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm
                                        .$moment(_vm.driver.booking.started_at)
                                        .format("dddd")
                                    ) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v("Período"),
                          ]),
                        ]),
                        _c("p", { staticClass: "card-text" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.driver.booking.plan.name)),
                          ]),
                          _c("span", { staticClass: "detail" }, [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.driver.booking.transaction_type) +
                                ")"
                            ),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v("Plano Ativo"),
                          ]),
                        ]),
                        _c("p", { staticClass: "card-text" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.setPriceFormat(
                                  _vm.driver.booking.transaction_amount
                                )
                              )
                            ),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v("Valor do plano"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.driver.emergency_1_phone_number ||
                  _vm.driver.emergency_2_phone_number ||
                  _vm.driver.phone_number ||
                  _vm.driver.email
                    ? _c("b-card", { attrs: { title: "Contatos" } }, [
                        _c(
                          "p",
                          { staticClass: "card-text" },
                          [
                            _c(
                              "b-link",
                              {
                                attrs: {
                                  href: "tel:" + _vm.driver.phone_number,
                                },
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.setPhoneFormat(
                                        _vm.driver.phone_number
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c("br"),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v("Número principal"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "card-text" },
                          [
                            _c(
                              "b-link",
                              { attrs: { href: "mailto:" + _vm.driver.email } },
                              [_c("strong", [_vm._v(_vm._s(_vm.driver.email))])]
                            ),
                            _c("br"),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v("Email"),
                            ]),
                          ],
                          1
                        ),
                        _vm.driver.emergency_1_phone_number
                          ? _c(
                              "p",
                              { staticClass: "card-text" },
                              [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      href:
                                        "tel:" +
                                        _vm.driver.emergency_1_phone_number,
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.setPhoneFormat(
                                            _vm.driver.emergency_1_phone_number
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(_vm.driver.emergency_1_name)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.driver.emergency_2_phone_number
                          ? _c(
                              "p",
                              { staticClass: "card-text" },
                              [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      href:
                                        "tel:" +
                                        _vm.driver.emergency_2_phone_number,
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.setPhoneFormat(
                                            _vm.driver.emergency_2_phone_number
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(_vm.driver.emergency_2_name)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.getDriverAddress(_vm.driver)
                    ? _c("b-card", { attrs: { title: "Endereço" } }, [
                        _c("p", {
                          staticClass: "card-address",
                          domProps: {
                            innerHTML: _vm._s(_vm.getDriverAddress(_vm.driver)),
                          },
                        }),
                        _c(
                          "p",
                          { staticClass: "card-address-search" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  href: `https://www.google.com/maps/search/${_vm.getDriverAddress(
                                    _vm.driver,
                                    true
                                  )}`,
                                  target: "_blank",
                                  size: "sm",
                                  variant: "primary",
                                  title: "Abrir no Google Maps",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-map-o" }),
                                _vm._v(" Google Maps "),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.driver.garage_other_address
                          ? _c("div", { staticClass: "card-text" }, [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Endereço Garagem "),
                              ]),
                              _c("p", { staticClass: "card-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDriverGarageAddress(_vm.driver)
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.$regions.isBrazil()
                    ? _c("b-card", { attrs: { title: "Conta Bancária" } }, [
                        _vm.account
                          ? _c("div", [
                              _c("p", { staticClass: "card-text" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.account.bank_name)),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("Banco"),
                                ]),
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.account.branch)),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("Agencia"),
                                ]),
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.account.number)),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("Conta"),
                                ]),
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.setTypeAccount(
                                        _vm.account.account_type
                                      )
                                    )
                                  ),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("Tipo"),
                                ]),
                              ]),
                            ])
                          : _c("div", [
                              _c("p", [_vm._v("Não há contas cadastradas.")]),
                            ]),
                        _c(
                          "div",
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  type: "button",
                                  variant: "primary",
                                  size: "sm",
                                },
                                on: { click: _vm.showModal },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("payments.labels.updateBankData")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
      _c("modal-bank-data", {
        on: { refresh: _vm.getDefaultBankAccount },
        model: {
          value: _vm.showModalBankData,
          callback: function ($$v) {
            _vm.showModalBankData = $$v
          },
          expression: "showModalBankData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }