var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.booking.status === "ACTIVE" && _vm.contract.id
    ? _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticStyle: { padding: "25px 10px" } },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "margin-right": "5px",
                      },
                      attrs: { src: require("@assets/logos/lw-logo.webp") },
                    }),
                    _c("span", { staticClass: "driver-status-title" }, [
                      _vm._v(
                        _vm._s(_vm.cardObject.title) +
                          " Dia" +
                          _vm._s(Math.abs(_vm.cardObject.title) > 1 ? "s" : "")
                      ),
                    ]),
                  ]
                ),
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "driver-status-subtitle truncate" },
                    [_vm._v("FECHAMENTO DO CONTRATO")]
                  ),
                ]),
              ]),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover",
                      value: _vm.subtitleHover,
                      expression: "subtitleHover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticStyle: {
                    "text-align": "center",
                    "align-self": "self-end",
                    cursor: "pointer",
                  },
                },
                [
                  _c("i", {
                    class: `${_vm.cardObject.icon} text-${_vm.cardObject.color} font-4xl`,
                  }),
                  _c(
                    "b-col",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "font-size": "12px",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: `font-weight-bold text-${_vm.cardObject.color}`,
                        },
                        [_vm._v(_vm._s(_vm.cardObject.subtitle))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }