<template>
  <b-card style="height:300px; padding:0;">
    <b-tabs fill>
      <b-tab :title="$t('drivers.weekly.WeeklyKMlast')">
        <apexchart
          v-if="dataReady"
          type="line"
          width="100%"
          height="216"
          :options="chartOptions_last"
          :series="kms_last"
        />
      </b-tab>
      <b-tab :title="$t('drivers.weekly.WeeklyKMactual')">
        <apexchart
          v-if="dataReady"
          type="line"
          width="100%"
          height="216"
          :options="chartOptions_actual"
          :series="kms_actual"
        />
      </b-tab>
      <b-tab :title="`${$t('drivers.weekly.lastDays')} ${kms_all[0].data.length} dias`">
        <apexchart
          v-if="dataReady"
          type="line"
          width="100%"
          height="216"
          :options="chartOptions_all"
          :series="kms_all"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
export default {
  name: 'DriverMileageChart',
  props: {
    driver: {
      type: Object,
      default: () => {
      },
      required: false,
    },
  },
  data() {
    return {
      dataReady: false,
      kms_actual: [{
        data: [],
      }],
      kms_last: [{
        data: [],
      }],
      kms_all: [{
        data: [],
      }],
      chartOptions_actual: {
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: false,
        },
      },
      chartOptions_last: {
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: false,
        },
      },
      chartOptions_all: {
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: false,
        },
      },
    }
  },
  mounted() {
    this.mountCharts()
  },
  methods: {
    mountCharts() {
      const dailys_actual = []
      const axis_actual = []
      const dailys_last = []
      const axis_last = []
      const dailys_all = []
      const axis_all = []
      const [actual, last] = this.driver.carHistoryCycles.cycles;

      const actualCarHistory = actual?.carHistories.map((daily) => {
        dailys_actual.push(daily.daily_odometer || 0)
        axis_actual.push(this.$moment(daily.created_at).format('DD/MM'))
      });

      const lastCarHistory = last?.carHistories.map((daily) => {
        dailys_last.push(daily.daily_odometer || 0)
        axis_last.push(this.$moment(daily.created_at).format('DD/MM'))
      });

      const allCarHistory = this.driver.carHistories.items.filter((day) => {
        return day.created_at
      }).map((daily) => {
        dailys_all.push(daily.daily_odometer || 0)
        axis_all.push(this.$moment(daily.created_at).format('DD/MM'))
      });

      // chart ciclo atual
      this.kms_actual[0].data = dailys_actual;
      this.chartOptions_actual.xaxis.categories = axis_actual;

      // chart ciclo anterior
      this.kms_last[0].data = dailys_last;
      this.chartOptions_last.xaxis.categories = axis_last;

      // chart all days
      this.kms_all[0].data = dailys_all;
      this.chartOptions_all.xaxis.categories = axis_all;

      this.dataReady = true;

      return {
        actualCarHistory,
        lastCarHistory,
        allCarHistory,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  .card-body
    padding: 0
</style>
