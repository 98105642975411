<template>
  <b-card
    :bg-variant="bgRange"
    class="text-center text-white"
    body-class="flex"
  >
    <div>
      <p class="h1 mb-0 driver-behaviour-title full-width">
        {{ fines }}
      </p>
      <p class="text-uppercase font-weight-bold text-center full-width mb-0">
        {{ $t('component.widget.totalFines.title') }}
      </p>
    </div>
  </b-card>
</template>
<script>
import CardWidget from '@components/shared/card-widget';

export default {
  name: 'TotalFinesCard',
  components: {
    CardWidget,
  },
  props: {
    fines: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    bgRange() {
      if (this.fines === 0) return 'success';
      if (this.fines < 3) return 'info';
      if (this.fines < 9) return 'warning';
      if (this.fines >= 9) return 'danger';
    },
  },
}
</script>
<style scoped>
.flex {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}
</style>
