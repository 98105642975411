export const DBS_RATES = {
  EXCELLENT: 'A',
  GOOD: 'B',
  REGULAR: 'C',
  IMPROVE: 'D',
  BAD: 'E',
  NONE: 'none',
};

export const DBS_MEXICO = [
  { min: '76', max: '100', text: 'component.driverBehavior.driverScore.excellent', color: '#018f10' },
  { min: '51', max: '75', text: 'component.driverBehavior.driverScore.good', color: '#80C687' },
  { min: '26', max: '50', text: 'component.driverBehavior.driverScore.regular', color: '#FEFF03' },
  { min: '1', max: '25', text: 'component.driverBehavior.driverScore.improve', color: '#F44336' },
  { min: '0', max: '0', text: 'component.driverBehavior.driverScore.notfound', color: '#F44336' },
];
