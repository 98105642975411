var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showLockAlert
    ? _c(
        "div",
        { staticClass: "alert alert-danger mb-2", attrs: { role: "alert" } },
        [
          _c("h4", {
            staticClass: "alert-heading",
            domProps: { textContent: _vm._s(_vm.headingText) },
          }),
          _c("hr"),
          _c("p", [_vm._v(_vm._s(_vm.lockText))]),
          _vm.driver_promise_profile
            ? _c("p", { domProps: { innerHTML: _vm._s(_vm.getAlertText) } })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }