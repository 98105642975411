import { BADGE_BR } from '@enums/badges';
import { DBS_RATES } from '@enums/dbs';

const lightText = 'text-white';
const darkText = 'text-black';

export const getDbsScoreRangeInfoBR = score => {
  const rangesBR = [
    { min: 0, max: 29, rate: DBS_RATES.BAD, bgColor: BADGE_BR.BAD, textColor: lightText },
    { min: 30, max: 49, rate: DBS_RATES.IMPROVE, bgColor: BADGE_BR.IMPROVE, textColor: lightText },
    { min: 50, max: 64, rate: DBS_RATES.REGULAR, bgColor: BADGE_BR.REGULAR, textColor: darkText },
    { min: 65, max: 79, rate: DBS_RATES.GOOD, bgColor: BADGE_BR.GOOD, textColor: lightText },
    { min: 80, max: 100, rate: DBS_RATES.EXCELLENT, bgColor: BADGE_BR.EXCELLENT, textColor: lightText },
  ];
  const defaultRange = { min: 0, max: 0, rate: DBS_RATES.NONE, bgColor: BADGE_BR.NONE, textColor: darkText };

  if (typeof score !== 'number') {
    return defaultRange;
  }

  const rangeDetail = rangesBR.find(range => score >= range.min && score <= range.max);
  if (rangeDetail) return rangeDetail;

  return defaultRange;
};
