var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    [
      _c(
        "div",
        {
          staticClass:
            "mb-0 font-weight-bold card-value text-muted text-uppercase font-weight-bold",
        },
        [_vm._v(" " + _vm._s(_vm.$t("component.driverBehavior.title")) + " ")]
      ),
      _c("driver-behavior-score-graphic", {
        attrs: { id: "dbs-score-card", score: _vm.driverScore },
      }),
      _c(
        "b-popover",
        {
          attrs: {
            target: "dbs-score-card",
            triggers: "hover",
            placement: "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$t("component.driverBehavior.title")) + " "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.getHelpText("score", _vm.getDbsScoreRate(_vm.driverScore))
              ) +
              " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }