var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "text-center",
      attrs: {
        "bg-variant": _vm.getLicenseStatusColor(
          _vm.driver.license_points,
          _vm.driver.license_status
        ),
      },
    },
    [
      ["ACTIVE", "BLOCKED", "EXPIRED"].includes(_vm.driver.license_status)
        ? _c("div", [
            _c("div", { staticClass: "h1 mb-0 driver-behaviour-title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.driver.license_points !== null
                      ? _vm.driver.license_points
                      : "?"
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "small text-uppercase font-weight-bold text-center",
              },
              [_vm._v(" Pontos ")]
            ),
            _c("div", { staticClass: "text-uppercase mt-3" }, [
              _vm._v(_vm._s(_vm.getLicenseStatus(_vm.driver.license_status))),
            ]),
          ])
        : [
            "INVALID",
            "REQUIRES_MIRROR_NUMBER",
            "CNH_NOT_FOUND",
            "CPF_RENTAL_DENATRAN_DONT_MATCH",
          ].includes(_vm.driver.license_status)
        ? _c("div", [
            _c("div", { staticClass: "h1 mb-0 driver-status-title" }, [
              _vm._v("?"),
            ]),
            _c("div", { staticClass: "text-uppercase mt-3" }, [
              _vm._v(_vm._s(_vm.getLicenseStatus(_vm.driver.license_status))),
            ]),
          ])
        : _c("div", [
            _c("div", { staticClass: "h1 mb-0 driver-status-title" }, [
              _vm._v("?"),
            ]),
            _c("div", { staticClass: "text-uppercase mt-3" }, [
              _vm._v(" Processando "),
              _c("i", { staticClass: "ml-1 fa fa-spin fa-refresh" }),
            ]),
          ]),
      _c("div", { staticClass: "small text-updated mt-1" }, [
        _vm._v(
          " Verificado " +
            _vm._s(
              _vm._f("moment")(
                _vm.driver.checked_at
                  ? _vm.driver.checked_at
                  : _vm.driver.updated_at,
                "from",
                true
              )
            ) +
            " atrás "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }