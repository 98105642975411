<template>
  <b-card
    :bg-variant="getLicenseStatusColor(driver.license_points, driver.license_status)"
    class="text-center"
  >
    <div v-if="['ACTIVE', 'BLOCKED', 'EXPIRED'].includes(driver.license_status)">
      <div class="h1 mb-0 driver-behaviour-title">
        {{ driver.license_points !== null ? driver.license_points : '?' }}
      </div>
      <div class="small text-uppercase font-weight-bold text-center">
        Pontos
      </div>
      <div class="text-uppercase mt-3">{{ getLicenseStatus(driver.license_status) }}</div>
    </div>
    <div
      v-else-if="
            [
              'INVALID',
              'REQUIRES_MIRROR_NUMBER',
              'CNH_NOT_FOUND',
              'CPF_RENTAL_DENATRAN_DONT_MATCH',
            ].includes(driver.license_status)
          "
    >
      <div class="h1 mb-0 driver-status-title">?</div>
      <div class="text-uppercase mt-3">{{ getLicenseStatus(driver.license_status) }}</div>
    </div>
    <div v-else>
      <div class="h1 mb-0 driver-status-title">?</div>
      <div class="text-uppercase mt-3">
        Processando
        <i class="ml-1 fa fa-spin fa-refresh"/>
      </div>
    </div>
    <div class="small text-updated mt-1">
      Verificado
      {{ (driver.checked_at ? driver.checked_at : driver.updated_at) | moment('from', true) }}
      atrás
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */
import { makeQuery } from '@graphql/middleware'
import ContentLoading from '@components/content-loading';
import CAR_HISTORIES from '@graphql/car/queries/histories.gql';

export default {
  name: 'driver-license-status',
  components: {
    ContentLoading,
  },
  props: {
    driver: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      carHistories: {},
      dataReady: false,
    }
  },
  mounted() {
    makeQuery(
      CAR_HISTORIES,
      { id: this.driver.id },
    )
    .then((res) => {
      this.carHistories = res.data.carHistories;
      this.dataReady = true;
    })
  },
  methods: {
    getLicenseStatusColor(_points, _status) {
      if (_status === 'ACTIVE') {
        if (_points <= 13) {
          return 'success';
        } else if (_points >= 13 && _points <= 21) {
          return 'warning';
        } else if (_points > 21) {
          return 'danger';
        } else {
          return 'secondary';
        }
      } else if (_status === 'PENDING') {
        return 'warning';
      } else if (
        _status === 'BLOCKED' ||
        _status === 'INVALID' ||
        _status === 'CPF_RENTAL_DENATRAN_DONT_MATCH' ||
        _status === 'CNH_NOT_FOUND' ||
        _status === 'EXPIRED'
      ) {
        return 'danger';
      } else {
        return 'secondary';
      }
    },
    getLicenseStatus(_status) {
      if (!_status) return null;

      switch (_status) {
        case 'ACTIVE':
          return 'CNH Ativa';
          break;
        case 'BLOCKED':
          return 'CNH Bloqueada';
          break;
        case 'CHECKING':
          return 'Aguardando dados';
          break;
        case 'INVALID':
          return 'CNH Inválida';
          break;
        case 'REQUIRES_MIRROR_NUMBER':
          return 'Não foi possível consultar';
          break;
        case 'CPF_RENTAL_DENATRAN_DONT_MATCH':
          return 'CPF Denatran não confere com CPF Rental';
          break;
        case 'CNH_NOT_FOUND':
          return 'CNH Inexistente';
          break;
        case 'EXPIRED':
          return 'CNH Expirada';
          break;
        default:
          return null;
          break;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.driver-status-title
  font-size: 50px

.text-updated
  font-size: 12px
</style>
