<script>
import { BADGE_BR } from '@enums/badges';
import { DBS_RATES } from '@enums/dbs';
import { getDbsScoreRangeInfoBR } from '@utils/dbsScoreRange';

export default {
  name: 'ColorSquareBadge',
  props: {
    type: {
      type: String,
      default: 'class',
      validator: (value) => {
        return ['class', 'style'].includes(value)
      }
    },
    value: {
      type: [String, Number, null],
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: BADGE_BR.NONE,
    }
  },
  computed: {
    emptyString() {
      return '⠀';
    },
    badgeClass() {
      const baseClass = 'badge';

      if (this.type === 'style') {
        let defaultConfig = 'badge ';
        const { textColor } = getDbsScoreRangeInfoBR(this.value)
        return defaultConfig += textColor;
      }

      switch (this.color) {
        case BADGE_BR.GOOD:
          return `${baseClass} badge-success text-white`;
        case BADGE_BR.EXCELENT:
          return `${baseClass} badge-info text-white`;
        case BADGE_BR.REGULAR:
          return `${baseClass} badge-warning text-black`;
        case BADGE_BR.BAD:
          return `${baseClass} badge-danger text-white`;
        case BADGE_BR.NONE:
        default:
          return `${baseClass} badge-primary text-white`;
      }
    },
    badgeStyle() {
      if (this.type === 'class') return ''

      return this.color
    },
    displayRate() {
      const { rate } = getDbsScoreRangeInfoBR(this.value);
      const rateToShow = rate && rate === DBS_RATES.NONE ? '-' : rate
      return rateToShow || this.value
    }
  },
  render(createElement) {
    return createElement('span', {
      class: this.badgeClass,
      style: {
        backgroundColor: this.badgeStyle
      }
    }, this.displayRate || this.emptyString);
  },
}
</script>
