<script>
import { isMexico } from '@utils/helper-region'
import { DBS_RATES, DBS_MEXICO } from '@src/enums/dbs';
import { getDbsScoreRangeInfoBR } from '@utils/dbsScoreRange';

export default {
  name: 'DriverBehaviorScoreGraphic',
  props: {
    score: {
      type: [Number, null],
      required: false,
      default: 0,
    },
  },
  watch: {
    score() {
      this.$refs.chart.refresh();
    }
  },
  computed: {
    getScore() {
      return typeof this.score === 'number' ? this.score : '-';
    },
    chartOptions() {
      return {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: '40%',
            },
            track: {
              background: '#e7e7e7',
              strokeWidth: '100%',
              margin: 0,
              dropShadow: {
                enabled: false,
              }
            },
            dataLabels: {
              name: {
                fontSize: '15px',
                color: '#000',
                offsetY: 10
              },
              value: {
                offsetY: -36,
                fontSize: '32px',
                color: undefined,
                formatter: function (val) {
                  return val;
                }
              }
            },
          }
        },
        grid: {
          padding: {
            top: 0
          }
        },
        fill: {
          colors: [this.chartValue.color]
        },
        dropShadow: {
          enabled: false,
        },
        labels: [this.chartValue.text],
      };
    },
    chartValue() {
      if (isMexico()) {
        const listByRegion = DBS_MEXICO;
        for (const item of listByRegion) {
          if (this.score >= item.min && this.score <= item.max) {
            item.text = this.$t(item.text)
            return item
          }
        }
      }

      const { min, max, rate, bgColor: color } = getDbsScoreRangeInfoBR(this.score)
      const dbsInfo = {
        color,
        min: min.toString(),
        max: max.toString(),
        text: this.$t(`component.driverBehavior.driverScore.${rate}`)
      }

      return dbsInfo;
    }
  },
  render(createElement) {
    return createElement('apexchart', {
      props: {
        type: 'radialBar',
        options: this.chartOptions,
        series: [this.getScore],
        height: 280
      },
      ref: 'chart',
    });
  }
}
</script>
