<template>
  <b-card
    class="text-center weekly-kms"
    style="height:300px"
  >
    <div class="weekly-card">
      <div class="text-updated mt-1">
        {{ $t('drivers.weekly.WeeklyKMactual') }}
        <span
          v-if="WeeklyKMActual"
          class="cycle-text"
        >{{
            `( ${$moment(WeeklyKMActual, 'YYYY-MM-DD', true).format('DD/MM')} ${$t(
              'drivers.weekly.until')} ${$moment(WeeklyKMActual, 'YYYY-MM-DD', true)
            .add(6, 'days')
            .format('DD/MM')} )`
          }}</span>
      </div>
      <div
        v-if="actual_km"
        class="h1 mb-0">
        {{ actual_km }}
        <small>
          km
        </small>
      </div>
    </div>

    <div class="weekly-card">
      <div class="text-updated mt-1">
        {{ $t('drivers.weekly.WeeklyKMlast') }}
        <span
          v-if="WeeklyKMLast"
          class="cycle-text"
        >
            {{
            `( ${$moment(WeeklyKMLast,'YYYY-MM-DD', true).format('DD/MM')} ${$t(
              'drivers.weekly.until')} ${$moment(WeeklyKMLast, 'YYYY-MM-DD', true)
            .add(6, 'days')
            .format('DD/MM')} )`
          }}
          </span>
      </div>
      <div
        v-if="last_km"
        class="h1 mb-0">
        {{ last_km }}
        <small>
          km
        </small>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "WeeklyTraveled",
  props: {
    driver: {
      type: Object,
      default: () => {
      },
      required: false,
    },
  },
  data() {
    return {
      actual: [],
      last: [],
      actual_km: 0,
      last_km: 0,
    }
  },
  mounted() {
    this.mountComponentData()
  },
  computed: {
    WeeklyKMActual() {
      const [actual] = this.driver.carHistoryCycles.cycles;

      return actual?.start ? actual.start : undefined;
    },
    WeeklyKMLast(){
      const [, last] = this.driver.carHistoryCycles.cycles;

      return last?.start ? last.start : undefined;
    }
  },
  methods: {
    mountComponentData(){
      const [actual, last] = this.driver.carHistoryCycles.cycles;

      this.actual_km = actual?.carHistories.reduce((act, nex) => {
        return act + nex.daily_odometer;
      }, 0);

      this.last_km = last?.carHistories.reduce((act, nex) => {
        return act + nex.daily_odometer;
      }, 0);
    },
  },
}
</script>

<style lang="sass" scoped>
.weekly-kms
  background: #e9e9e9

  .weekly-card
    height: 50%

    &:nth-child(1)
      border-bottom: 1px solid rgb(200, 206, 211)
      padding-bottom: 20px

    &:nth-child(2)
      padding-top: 10px

    .text-updated
      font-size: 1rem

      .cycle-text
        display: block
        font-size: 12px
        font-weight: bold

    .h1
      font-size: 2.7rem
      margin-top: 5px

    .bolding
      margin-top: 5px
      font-size: 1.1rem
      font-weight: bold
</style>
