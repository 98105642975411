var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "card-pointer",
      class: { "card-active": this.active },
      attrs: { id: _vm.getId },
      on: {
        click: function ($event) {
          return _vm.goTo(_vm.to)
        },
      },
    },
    [
      _c("div", { staticClass: "h3 text-muted text-right mb-0" }, [
        _c("i", {
          class: { [this.icon]: this.icon, "icon-active": this.active },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "h4 mb-0 font-weight-bold card-value",
          attrs: { title: _vm.value },
        },
        [_vm._v(_vm._s(_vm.value))]
      ),
      _c(
        "small",
        {
          staticClass: "text-muted text-uppercase font-weight-bold",
          class: { "text-active": this.active },
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm.hasRecurrencyData
        ? _c("div", [
            _c("span", { staticClass: "d-block" }, [
              _vm._v(
                _vm._s(_vm.$t("recurrency.labels.amount")) +
                  ": " +
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.formatedAmount
                    )
                  ) +
                  " "
              ),
            ]),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(
                _vm._s(_vm.$t("recurrency.labels.next-date")) +
                  ": " +
                  _vm._s(_vm.formatedNextDate)
              ),
            ]),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recurrency.labels.weekly-pay-day")) +
                  ": " +
                  _vm._s(
                    _vm.$t(
                      `recurrency.labels.${_vm.weeklyPayDay.toLowerCase()}`
                    )
                  ) +
                  " (" +
                  _vm._s(_vm.$t(`recurrency.labels.${_vm.paymentMethodType}`)) +
                  ") "
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }