var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "text-center text-white",
      attrs: { "bg-variant": _vm.bgRange, "body-class": "flex" },
    },
    [
      _c("div", [
        _c("p", { staticClass: "h1 mb-0 driver-behaviour-title full-width" }, [
          _vm._v(" " + _vm._s(_vm.fines) + " "),
        ]),
        _c(
          "p",
          {
            staticClass:
              "text-uppercase font-weight-bold text-center full-width mb-0",
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("component.widget.totalFines.title")) + " "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }